import { westpac } from "../services";

export const GET_SPECIES = "GET_SPECIES";

export const getSpecies = () => {
  return dispatch => {
    return westpac.get("/species").then(({ data }) => {
      const species = parseSpeciesData(data.species);
      dispatch({ type: GET_SPECIES, payload: { species } });
    });
  };
};

export const parseSpeciesData = species => {
  return species.map(s => ({
    id: s.id,
    common_name: s.common_name,
    category: s.species_categories.category
  }));
};
