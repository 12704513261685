import moment from "moment";

import { QUARTERS, findQuarter } from "./utils";
/**
 *
 * @param {Array} approvedReports - An array of all approved reports.
 * @param {Moment} startDate - A moment instance representing the Dashboard's current viewing date.
 * @param {String} period - 'year' | 'month' | 'quarter'
 * @param {Array} marketAveragesBySpeciesList - An array of the average market price averages by species.
 * @param {Array} speciesList - An array of the fish species.
 *
 */
export default function aggregator(
  approvedReports,
  startDate,
  period,
  marketAveragesBySpeciesList,
  speciesList,
  lastYearsCatchTotals
) {
  let start, end;

  if (period === "year") {
    start = moment(startDate).startOf("year");
    end = moment(startDate).endOf("year");
  } else if (period === "quarter") {
    const year = moment(startDate).year();
    const month = moment(startDate).month();
    const quarter = findQuarter(month);

    start = moment({
      year,
      month: QUARTERS[quarter][0]
    });
    end = moment({ year, month: QUARTERS[quarter][2] }).endOf("month");
  } else if (period === "month") {
    const year = moment(startDate).year();
    const month = moment(startDate).month();

    start = moment({ year, month });
    end = moment({ year, month }).endOf("month");
  }

  const aggregatedReport = {
    totalFishCaught: 0,
    totalPoundsBought: 0,
    totalPoundsSold: 0,
    totalSales: 0,
    totalTripCount: 0,
    purchasesByFisher: {},
    purchasedPoundsByMonth: {}
  };
  const species = {};

  approvedReports.forEach(report => {
    const isSelectedPeriodReport = moment(report.date_of_purchase).isBetween(
      start,
      end,
      null,
      "[]"
    );
    const isAnnualReport = moment(report.date_of_purchase).isBetween(
      moment(startDate).startOf("year"),
      moment(startDate).endOf("year"),
      null,
      "[]"
    );

    if (isAnnualReport) {
      report.fishing_trip.sales_order.forEach(({ items }) => {
        items.forEach(item => {
          const totalCount = item.total_count;
          const totalSales = item.total_weight_lbs * item.price_per_lb_usd;
          const totalLbs = item.total_weight_lbs;
          aggregatedReport.totalPoundsBought += totalLbs;
          if (
            aggregatedReport.purchasedPoundsByMonth[
              moment(report.date_of_purchase).format("MM")
            ]
          ) {
            aggregatedReport.purchasedPoundsByMonth[
              moment(report.date_of_purchase).format("MM")
            ] += totalLbs;
          } else {
            aggregatedReport.purchasedPoundsByMonth[
              moment(report.date_of_purchase).format("MM")
            ] = totalLbs;
          }
          if (
            aggregatedReport.purchasesByFisher.hasOwnProperty(
              report.first_purchased_from
            )
          ) {
            aggregatedReport.purchasesByFisher[
              report.first_purchased_from
            ] += totalLbs;
          } else {
            aggregatedReport.purchasesByFisher[
              report.first_purchased_from
            ] = totalLbs;
          }
          if (species.hasOwnProperty(item.species_id)) {
            species[item.species_id].total_count += totalCount;
            species[item.species_id].total_sales += totalSales;
            species[item.species_id].total_weight_lbs += totalLbs;
          } else {
            species[item.species_id] = {
              total_count: totalCount,
              total_sales: totalSales,
              total_weight_lbs: totalLbs
            };
          }
        });
      });
    }

    if (isSelectedPeriodReport) {
      aggregatedReport.totalTripCount++;
      report.fishing_trip.sales_order.forEach(({ items }) => {
        items.forEach(item => {
          const totalSales = item.total_weight_lbs * item.price_per_lb_usd;
          aggregatedReport.totalSales += totalSales;
          aggregatedReport.totalPoundsSold += item.total_weight_lbs;
        });
      });
    }
  });

  const entries = Object.entries(species);
  const salesBySpeciesOfFish = [];
  const marketAveragesBySpecies = {};

  marketAveragesBySpeciesList.forEach(speciesEntry => {
    if (speciesEntry.species) {
      marketAveragesBySpecies[speciesEntry.species.id] = {
        month_average_market_price_usd:
          speciesEntry.month_average_market_price_usd,
        year_average_market_price_usd:
          speciesEntry.year_average_market_price_usd
      };
    }
  });

  for (const [id, species] of entries) {
    salesBySpeciesOfFish.push({
      id,
      avg_price_per_lb: species.total_sales / species.total_weight_lbs,
      total_lbs: species.total_weight_lbs,
      market_avg: marketAveragesBySpecies[id]
        ? marketAveragesBySpecies[id].year_average_market_price_usd
        : 0
    });
  }

  return {
    ...aggregatedReport,
    salesBySpeciesOfFish,
    marketAveragesBySpecies,
    lastYearsCatchTotals
  };
}
