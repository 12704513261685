import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from '@reach/router';
import PropTypes from 'prop-types';

const AuthenticatedRoute = ({ Component, isAuthenticated, ...rest }) => {
  if (isAuthenticated) {
    return <Component {...rest} />;
  }

  return <Redirect to="/" noThrow />;
};

AuthenticatedRoute.propTypes = {
  Component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated
});

export default connect(mapStateToProps)(AuthenticatedRoute);
