import moment from "moment";
import { getApprovedReports } from "./dashboard";
import { UPDATE_VENDOR_REPORT_DONE } from "../actions/reports";

const INITIAL_VENDOR_REPORTS_STATE = [];

export const initialSaleState = {
  species_category: null,
  species_id: null,
  total_count: 0,
  total_weight_lbs: 0.0,
  price_per_lb_usd: 0.0,
  sold_to_vendor: true,
  vendor_business_license_number: ""
};

export const initialState = {
  is_approved: null,
  submitted_at: null,
  date_of_purchase: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
  first_purchased_from: "",
  sales_order: [
    {
      items: [initialSaleState]
    }
  ]
};

export default function vendorReportsReducer(
  state = INITIAL_VENDOR_REPORTS_STATE,
  action = {}
) {
  switch (action.type) {
    case "GET_VENDOR_REPORTS":
      return action.payload.data;
    case "ADD_VENDOR_REPORT":
      return state.concat(action.payload.report);
    case "UPDATE_VENDOR_REPORT":
      return state.map(vendorReport => {
        if (action.payload.revision.id === vendorReport.id) {
          return action.payload.revision;
        } else {
          return vendorReport;
        }
      });
    case UPDATE_VENDOR_REPORT_DONE:
      return state.map(vendorReport => {
        if (action.payload.id === vendorReport.id) {
          return {
            ...vendorReport,
            done: true
          };
        } else {
          return vendorReport;
        }
      });
    case "DELETE_VENDOR_REPORT":
      return state.filter(vendorReport => {
        return vendorReport.id !== action.payload.id;
      });
    default:
      return state;
  }
}
