import React from "react";
import { withStyles } from "@material-ui/styles";
import MuiGrid from "@material-ui/core/Grid";

const styles = theme => ({});

const GridItem = ({ ...rest }) => {
  return <MuiGrid item {...rest} />;
};

export default withStyles(styles)(GridItem);
