import React from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import moment from "moment";

import { Button, Card, CardBody, Divider, Typography } from "../../components";
import { scrollToTop } from "../../views/Reports/utils";

const styles = {
  resubmitButton: {
    display: "inline-block",
    margin: "0 auto",
    padding: "0 20px",
    fontSize: "12px",
    background: "#23408E",
    color: "white",
    float: "left"
  },
  markAsDoneButton: {
    display: "inline-block",
    margin: "0 auto",
    padding: "0",
    fontSize: "12px",
    background: "white",
    color: "#23408E",
    float: "right"
  },
  rejectedButtonText: {
    fontSize: "12px",
    fontWeight: "bold"
  },
  reasonHeader: {
    color: "#AC2828",
    paddingBottom: "0",
    fontSize: "12px"
  },
  reportText: {
    padding: "0 0 12px",
    fontSize: "12px"
  },
  reportCard: {
    marginBottom: "10px"
  }
};

const RemediationCard = ({
  classes,
  report,
  setSavedFormValues,
  setInitialFormState,
  setIsNewReport,
  setPageOffset,
  markAsDone,
  selectedReportsTab,
  handleShowDetails,
  ...rest
}) => {
  const { id, submitted_at, reason, done } = report;

  return (
    <Card className={classes.reportCard}>
      <CardBody
        style={{
          cursor: "pointer",
          padding: "8px 0"
        }}
        onClick={handleShowDetails}
      >
        <p
          style={{
            margin: "0"
          }}
        >
          Report #{id} - Submitted{" "}
          {moment(submitted_at).format("MMMM DD, YYYY")}
        </p>
      </CardBody>
      <CardBody className={classes.reportText}></CardBody>
      {selectedReportsTab === "rejected" ? (
        <>
          <Divider
            style={{
              margin: "0px 8px"
            }}
          />
          <CardBody className={classes.reasonHeader}>
            Reason for Rejection:
          </CardBody>
          <CardBody className={classes.reportText}>{reason}</CardBody>
          {!done ? (
            <CardBody>
              <Button
                onClick={() => {
                  const rejectedReport = {
                    ...report,
                    ...report.fishing_trip
                  };
                  delete rejectedReport["id"];
                  setSavedFormValues(rejectedReport);
                  setInitialFormState(rejectedReport);
                  setIsNewReport(true);
                  setPageOffset(1);
                  scrollToTop();
                }}
                className={classes.resubmitButton}
              >
                <Typography className={classes.rejectedButtonText}>
                  Edit and Resubmit New Report
                </Typography>
              </Button>
              <Button
                className={classes.markAsDoneButton}
                fontSize="12px"
                onClick={() => {
                  markAsDone(id);
                }}
              >
                <Typography className={classes.rejectedButtonText}>
                  Mark as Done
                </Typography>
              </Button>
            </CardBody>
          ) : null}
        </>
      ) : null}
    </Card>
  );
};

RemediationCard.propTypes = {
  className: PropTypes.string
};

export default withStyles(styles)(RemediationCard);
