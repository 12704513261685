import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { bindActionCreators } from "redux";

import { initialSaleState, initialState } from "../../reducers/vendor-reports";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  GridContainer,
  GridItem,
  Form,
  FormControl,
  Input,
  InputLabel,
  SelectSearch,
  Tab,
  PeriodTabs,
  Typography,
  ListItemText,
  RemediationCard,
  Tooltip
} from "../../components";
import useFields from "../../hooks/useFields";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { Field, FieldArray } from "formik";
import moment from "moment";
import numeral from "numeral-es6";

import {
  addReport,
  updateReport,
  deleteReport,
  getReports,
  markVendorReportDone
} from "../../actions/reports";

import { SchemaValidation, scrollToTop } from "./utils";
import { Divider } from "@material-ui/core";

const styles = theme => ({
  buttonOutline: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: "#fff",
    color: theme.palette.primary.main,
    padding: "0 0.5rem",
    width: "fit-content"
  },
  backButton: {
    background: "transparent",
    color: theme.palette.primary.main,
    fontWeight: "700",
    left: "0",
    position: "absolute",
    top: "1.8rem"
  },
  bottomButtonBar: {
    position: "fixed",
    bottom: "0",
    left: "0",
    padding: "1rem",
    background: "#fff",
    boxShadow: "0 -4px 6px 0px rgba(0,0,0,0.2)",
    zIndex: "10000"
  },
  box: {
    border: "1px solid #ccc",
    margin: `${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(0.5)}px`
  },
  container: {
    margin: 0,
    paddingTop: "1rem"
  },
  card: {
    width: "100%",
    margin: `${theme.spacing(1)}px`,
    minHeight: "88vh",
    position: "relative"
  },
  cardBody: {
    paddingBottom: theme.spacing(12)
  },
  countSpan: {
    color: "#fff",
    background: theme.palette.primary.main,
    borderRadius: "3px",
    marginLeft: "1rem",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`
  },
  crewWrapper: {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    marginBottom: "6px",
    padding: "0.2rem 0.4rem"
  },
  crewCountContainer: {
    float: "right"
  },
  crewCountSpan: {
    color: "#fff",
    background: theme.palette.primary.main,
    borderRadius: "3px",
    marginLeft: "0.4rem",
    padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`
  },
  divider: {
    margin: "1rem 0 1.5rem"
  },
  dividerSmallPadding: {
    margin: "0.5rem 0"
  },
  editButton: {
    color: theme.palette.primary.main,
    fontWeight: "700",
    position: "absolute",
    right: "6px",
    top: "6px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  errorSpan: {
    color: "#ed7637",
    fontWeight: "700",
    fontSize: "1rem",
    float: "right"
  },
  eventOptions: {
    position: "absolute",
    top: "0.5rem",
    right: "0",
    padding: "0.2rem 0.6rem 0.2rem 0.6rem",
    background: "#fff"
  },
  eventOptionItems: {
    margin: "0",
    padding: "0.2rem 0.6rem 0.2rem 0.6rem",
    border: "1px solid #e0e0e0"
  },
  fieldSpacingWrapper: {
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "0.5rem",
    marginBottom: "0.2rem"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    width: "100%"
  },
  hidden: {
    display: "none"
  },
  hiddenEvent: {
    display: "initial"
  },
  mapContainer: {
    height: "180px"
  },
  name: {
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: "500"
  },
  pendingCardHeader: {
    color: theme.palette.primary.main,
    display: "flex",
    fontSize: "1rem",
    justifyContent: "space-between"
  },
  pendingCardStep: {
    alignSelf: "center",
    color: theme.palette.primary.main,
    float: "right",
    fontSize: "1rem"
  },
  pendingCardBody: {
    textTransform: "capitalize"
  },
  pendingReportBox: {
    border: "1px solid #ccc",
    margin: `${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(0.5)}px`,
    "&:hover": {
      cursor: "pointer"
    }
  },
  primaryTitle: {
    fontWeight: "900",
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1)
  },
  primaryCard: {
    fontWeight: "400",
    fontSize: "1.375rem",
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: "3px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
    textAlign: "left",
    "&:hover": {
      cursor: "pointer"
    }
  },
  primaryHeader: {
    padding: "0"
  },
  proceedButton: {
    float: "right",
    background: theme.palette.primary.main,
    color: "#fff",
    padding: "6px"
  },
  radioGroup: {
    paddingTop: "0.5rem"
  },
  removeCrewMemberBtn: {
    alignSelf: "flex-end",
    color: theme.palette.primary.main,
    fontWeight: "600"
  },
  reviewItem: {
    marginBottom: "0.5rem"
  },
  selectAreasButton: {
    position: "absolute",
    bottom: "0",
    right: "0",
    background: "rgba(35,64,142,1)",
    color: "#FFFFFF",
    zIndex: "10000",
    fontWeight: "700"
  },
  skipSales: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  smallHeader: {
    fontSize: "1rem",
    marginBottom: "0.5rem"
  },
  submitBtn: {
    width: "100%",
    maxWidth: "192px",
    margin: "auto",
    background: theme.palette.primary.main,
    color: "#fff",
    marginBottom: "6px"
  },
  styledDetail: {
    "& summary": {
      cursor: "pointer",
      listStyle: "none",
    },
    "& summary::-webkit-details-marker": {
      display: "none"
    }
  },
  visible: {
    display: "initial",
    width: "100%",
    paddingBottom: "2rem"
  },
  visibleEvent: {
    display: "initial"
  },
  vesselLicenseRight: {
    float: "right",
    fontSize: "1rem",
    fontWeight: "500"
  }
});

const CustomDivider = withStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))(({ classes }) => <Divider className={classes.divider} />);

const Reports = props => {
  const {
    classes,
    addReport,
    updateReport,
    species,
    vendorReports,
    fisherLicenses,
    speciesCategories,
    markVendorReportDone
  } = props;
  const fields = useFields();
  const [initialFormState, setInitialFormState] = useState({
    ...initialState,
    verified: false
  });
  const [savedFormValues, setSavedFormValues] = useState({
    ...initialFormState
  });
  const [currentReportId, setCurrentReportId] = useState(null);
  const [isNewReport, setIsNewReport] = useState(true);
  const [pageOffset, setPageOffset] = useState(0);
  const [reportError, setReportError] = useState("");
  const [showSubmittedReports, setShowSubmittedReports] = useState(false);
  const [selectedReportsTab, setSetSelectedReportsTab] = useState("rejected");

  function saveReport({ newReport, originalReport, revisionReport, id }) {
    if (!newReport) {
      return updateReport({
        originalReport,
        revisionReport,
        id
      }).then(data => {
        return data;
      });
    }
    return addReport(originalReport).then(data => {
      return data;
      setCurrentReportId(data.id);
    });
  }

  const defaultGenericReport = {
    ...initialState
  };

  function markAsDone(reportId) {
    markVendorReportDone(reportId);
  }

  function renderFilteredVendorReports(vendorReports, props) {
    const { fields } = props;
    const filteredReports = vendorReports.filter((report, i) => {
      if (selectedReportsTab === "rejected") {
        return report.is_approved === false;
      } else if (selectedReportsTab === "approved") {
        return report.is_approved === true;
      } else if (selectedReportsTab === "pending") {
        return report.is_approved === null && report.submitted_at;
      }
    });

    let REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP = null;
    if (fields.has("REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP")) {
      REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP = fields.get(
        "REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP"
      );
    }

    return (
      <>
        {selectedReportsTab === "rejected" && (
          <div>
            <span className={classes.reportText}>
              {REMEDY_REJECTED_VENDOR_REPORT_TOOLTIP}
            </span>
            <Divider
              style={{
                margin: "8px 0"
              }}
            />
          </div>
        )}
        {filteredReports.map((report, i) => {
          return (
            <RemediationCard
              report={report}
              setSavedFormValues={setSavedFormValues}
              setInitialFormState={setInitialFormState}
              setIsNewReport={setIsNewReport}
              setPageOffset={setPageOffset}
              markAsDone={markAsDone}
              selectedReportsTab={selectedReportsTab}
              handleShowDetails={() => {
                setSavedFormValues({
                  ...report,
                  ...report.fishing_trip
                });
                setIsNewReport(false);
                setCurrentReportId(report.id);
                setPageOffset(3);
                scrollToTop();
              }}
            />
          );
        })}
      </>
    );
  }

  return (
    <GridContainer
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <div
            className={
              pageOffset === 0 && showSubmittedReports !== true
                ? classes.visible
                : classes.hidden
            }
          >
            <div>
              <CardHeader className={classes.primaryHeader} title>
                <Typography className={classes.primaryCard} variant="h6">
                  <GridContainer>
                    <GridItem
                      xs={11}
                      onClick={() => {
                        setPageOffset(1);
                        scrollToTop();
                        setInitialFormState({
                          ...defaultGenericReport
                        });
                        setSavedFormValues({ ...defaultGenericReport });
                        setIsNewReport(true);
                      }}
                    >
                      New Vendor Report{" "}
                    </GridItem>
                    <GridItem xs={1}>
                      <Tooltip field="NEW_VENDOR_REPORT_TOOLTIP" />
                    </GridItem>
                  </GridContainer>
                </Typography>
              </CardHeader>
            </div>
            <details className={classes.styledDetail}>
              <summary>
                <CardHeader className={classes.primaryHeader} title>
                  <Typography className={classes.primaryCard} variant="h6">
                    <GridContainer>
                      <GridItem xs={11}>
                        Pending Vendor Reports
                        <span className={classes.countSpan}>
                          {
                            vendorReports.filter(
                              vendorReport => !vendorReport.submitted_at
                            ).length
                          }
                        </span>
                      </GridItem>
                      <GridItem xs={1}>
                        <Tooltip field="PENDING_VENDOR_REPORT_TOOLTIP" />
                      </GridItem>
                    </GridContainer>
                  </Typography>
                </CardHeader>
              </summary>
              {vendorReports.map((report, index) => {
                const vendorReport = {
                  ...report,
                  ...report.fishing_trip,
                  submitted_at: report.submitted_at,
                  is_approved: report.is_approved
                };
                if (!vendorReport.submitted_at) {
                  const { date_of_purchase, updated_at } = vendorReport;
                  return (
                    <div
                      className={classes.pendingReportBox}
                      onClick={() => {
                        setSavedFormValues({
                          ...vendorReport,
                          ...vendorReport.fishing_trip
                        });
                        setInitialFormState({
                          ...vendorReport,
                          ...vendorReport.fishing_trip
                        });
                        setIsNewReport(false);
                        setCurrentReportId(vendorReport.id);
                        setPageOffset(1);
                        scrollToTop();
                      }}
                      key={`fishing-report-pending-item-${index}`}
                    >
                      <div className={classes.pendingCardHeader}>
                        <span>
                          {moment(date_of_purchase).format("MMMM DD, YYYY")}
                        </span>
                      </div>
                      <span>
                        Last updated{" "}
                        {moment(updated_at).format("MMMM DD, YYYY")}
                      </span>
                    </div>
                  );
                }
              })}
            </details>
            <div className={classes.styledDetail}>
              <div>
                <CardHeader className={classes.primaryHeader} title>
                  <Typography className={classes.primaryCard} variant="h6">
                    <GridContainer>
                      <GridItem
                        xs={11}
                        onClick={() => {
                          setShowSubmittedReports(true);
                          setPageOffset(0);
                          scrollToTop();
                          setInitialFormState({
                            ...defaultGenericReport
                          });
                          setSavedFormValues({ ...defaultGenericReport });
                          setIsNewReport(true);
                        }}
                      >
                        Submitted Reports{" "}
                      </GridItem>
                      <GridItem xs={1}>
                        <Tooltip field="SUBMITTED_VENDOR_REPORT_TOOLTIP" />
                      </GridItem>
                    </GridContainer>
                  </Typography>
                </CardHeader>
              </div>
            </div>
            <div
              className={
                pageOffset === 0 && showSubmittedReports === true
                  ? classes.visible
                  : classes.hidden
              }
            >
              <Button
                className={classes.backButton}
                onClick={() => {
                  scrollToTop();
                  setShowSubmittedReports(false);
                }}
              >
                &#8592; Back
              </Button>
              <CardHeader title underline style={{ marginBottom: "1rem" }}>
                <Typography variant="h6" style={{ marginBottom: "8px" }}>
                  Submitted Reports
                </Typography>
                <PeriodTabs>
                  <Tab
                    active={selectedReportsTab === "rejected"}
                    onClick={() => setSetSelectedReportsTab("rejected")}
                  >
                    Rejected
                  </Tab>
                  <Tab
                    active={selectedReportsTab === "pending"}
                    onClick={() => setSetSelectedReportsTab("pending")}
                  >
                    Pending
                  </Tab>
                  <Tab
                    active={selectedReportsTab === "approved"}
                    onClick={() => setSetSelectedReportsTab("approved")}
                  >
                    Approved
                  </Tab>
                </PeriodTabs>
              </CardHeader>
            </div>
          </div>
          <div
            className={
              pageOffset === 0 && showSubmittedReports === true
                ? classes.visible
                : classes.hidden
            }
          >
            <Button
              className={classes.backButton}
              onClick={() => {
                scrollToTop();
                setShowSubmittedReports(false);
              }}
            >
              &#8592; Back
            </Button>
            <CardHeader title underline style={{ marginBottom: "1rem" }}>
              <Typography variant="h6" style={{ marginBottom: "8px" }}>
                Submitted Reports
              </Typography>
              <PeriodTabs>
                <Tab
                  active={selectedReportsTab === "rejected"}
                  onClick={() => setSetSelectedReportsTab("rejected")}
                >
                  Rejected
                </Tab>
                <Tab
                  active={selectedReportsTab === "pending"}
                  onClick={() => setSetSelectedReportsTab("pending")}
                >
                  Pending
                </Tab>
                <Tab
                  active={selectedReportsTab === "approved"}
                  onClick={() => setSetSelectedReportsTab("approved")}
                >
                  Approved
                </Tab>
              </PeriodTabs>
            </CardHeader>
            {renderFilteredVendorReports(vendorReports, {
              fields: fields
            })}
          </div>
          <Form
            className={classes.reportsForm}
            initialValues={initialFormState}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              SchemaValidation.validate(values)
                .then(() => {
                  saveReport({
                    newReport: isNewReport,
                    originalReport: { ...savedFormValues },
                    revisionReport: {
                      ...values,
                      submitted_at: true,
                      is_approved: null,
                      id: currentReportId
                    },
                    id: currentReportId
                  })
                    .then(() => {
                      setInitialFormState({
                        ...defaultGenericReport
                      });
                      actions.resetForm(defaultGenericReport);
                      window.alert(
                        "Your report has been submitted successfully.\nYou can check the status of this report by viewing Submitted Reports"
                      );
                      setPageOffset(0);
                      scrollToTop();
                    })
                    .catch(err => {
                      window.alert(
                        "There has been an error submitting your report. Please try again later"
                      );
                    });
                })
                .catch(err => {
                  setReportError(err.message);
                });
            }}
            render={({ values, handleSubmit, setFieldValue }) => {
              const handleFloatInputChange = name => event => {
                setFieldValue(name, numeral(event.target.value).format("0.00"));
              };

              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  {/**************************************/}
                  {/***** PAGE 1: Sales Information ******/}
                  {/**************************************/}
                  <div
                    className={
                      pageOffset === 1 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader
                      title
                      underline
                      style={{ marginBottom: "1rem" }}
                    >
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Vendor Report
                      </Typography>
                    </CardHeader>

                    <InputLabel htmlFor="date_of_purchase">
                      Date of Purchase
                    </InputLabel>
                    <FormControl fullWidth>
                      <Input
                        id="date_of_purchase"
                        name="date_of_purchase"
                        placeholder="Month / Day / Year : Time"
                        type="datetime-local"
                      />
                    </FormControl>

                    <InputLabel htmlFor={"first_purchased_from"}>
                      Fish Purchased From (Fishing License Number)
                    </InputLabel>
                    <FormControl fullWidth>
                      <Field
                        id={"first_purchased_from"}
                        name={"first_purchased_from"}
                        component="select"
                      >
                        <option selected="true" value="">
                          Select Fishing License Number
                        </option>
                        {fisherLicenses &&
                          fisherLicenses.map((item, itemIndex) => {
                            return (
                              <option
                                value={item}
                                key={`fisherLicense-${itemIndex}`}
                              >
                                {item}
                              </option>
                            );
                          })}
                      </Field>
                    </FormControl>

                    <Divider style={{ margin: "1rem 0" }} />

                    <FieldArray
                      name="sales_order"
                      render={arrayHelpers => (
                        <div>
                          {values.sales_order.map((sale, saleIndex) => {
                            return (
                              <div key={`sales_information_${saleIndex}`}>
                                <FieldArray
                                  name={`sales_order[${saleIndex}].items`}
                                  render={arrayHelpers2 => {
                                    return (
                                      <div>
                                        {values.sales_order[
                                          saleIndex
                                        ].items.map(
                                          (orderItem, orderItemIndex) => {
                                            let defaultSpeciesValue = null;
                                            let defaultCategoryValue = null;

                                            const foundSpecies = species.find(
                                              item =>
                                                orderItem.species_id === item.id
                                            );
                                            if (foundSpecies) {
                                              defaultSpeciesValue = {
                                                label: foundSpecies.common_name,
                                                value: foundSpecies.id
                                              };
                                              defaultCategoryValue =
                                                foundSpecies.category;
                                            }

                                            return (
                                              <div
                                                key={`order_${orderItemIndex}`}
                                                className={
                                                  classes.fieldSpacingWrapper
                                                }
                                              >
                                                <InputLabel
                                                  htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].species_category`}
                                                >
                                                  Species Category
                                                </InputLabel>
                                                <FormControl fullWidth>
                                                  <Field
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].species_category`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].species_category`}
                                                    component="select"
                                                    onChange={e => {
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].species_category`,
                                                        e.target.value
                                                      );
                                                      setFieldValue(
                                                        `sales_order[${saleIndex}].items[${orderItemIndex}].species_id`,
                                                        null
                                                      );
                                                    }}
                                                    value={defaultCategoryValue}
                                                  >
                                                    <option
                                                      selected="true"
                                                      disabled="disabled"
                                                      value="none"
                                                    >
                                                      Choose Species Category
                                                    </option>
                                                    {speciesCategories.map(
                                                      item => {
                                                        const {
                                                          category
                                                        } = item;
                                                        return (
                                                          <option
                                                            value={category}
                                                            key={category}
                                                          >
                                                            {category}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </Field>
                                                </FormControl>

                                                <InputLabel
                                                  htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                >
                                                  Species
                                                </InputLabel>
                                                <FormControl fullWidth>
                                                  <Field
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].species_id`}
                                                    component={SelectSearch}
                                                    isSearchable={true}
                                                    value={
                                                      values.sales_order[
                                                        saleIndex
                                                      ].items[orderItemIndex]
                                                        .species_id
                                                    }
                                                    options={species
                                                      .filter(item => {
                                                        return (
                                                          item.category ===
                                                            values.sales_order[
                                                              saleIndex
                                                            ].items[
                                                              orderItemIndex
                                                            ]
                                                              .species_category ||
                                                          item.category ===
                                                            defaultCategoryValue
                                                        );
                                                      })
                                                      .map(
                                                        (item, itemIndex) => {
                                                          return {
                                                            label:
                                                              item.common_name,
                                                            value: item.id
                                                          };
                                                        }
                                                      )}
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%",
                                                    paddingRight: "6px"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                  >
                                                    Count bought
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].total_count`}
                                                    placeholder="Total count"
                                                    type="number"
                                                    inputProps={{
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                  >
                                                    Total Pounds
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`}
                                                    placeholder="Total weight"
                                                    type="number"
                                                    onBlur={handleFloatInputChange(
                                                      `sales_order[${saleIndex}].items[${orderItemIndex}].total_weight_lbs`
                                                    )}
                                                    inputProps={{
                                                      step: "0.01",
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl
                                                  style={{
                                                    width: "50%",
                                                    paddingRight: "6px"
                                                  }}
                                                >
                                                  <InputLabel
                                                    htmlFor={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                  >
                                                    Price per lb
                                                  </InputLabel>
                                                  <Input
                                                    id={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                    name={`sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`}
                                                    placeholder="Price per lb"
                                                    type="number"
                                                    onBlur={handleFloatInputChange(
                                                      `sales_order[${saleIndex}].items[${orderItemIndex}].price_per_lb_usd`
                                                    )}
                                                    inputProps={{
                                                      step: "0.01",
                                                      min: "0"
                                                    }}
                                                  />
                                                </FormControl>

                                                <FormControl>
                                                  <Button
                                                    className={
                                                      classes.buttonOutline
                                                    }
                                                    style={{ margin: "auto" }}
                                                    type="button"
                                                    onClick={() =>
                                                      arrayHelpers2.remove(
                                                        orderItemIndex
                                                      )
                                                    }
                                                  >
                                                    Remove Species
                                                  </Button>
                                                </FormControl>
                                              </div>
                                            );
                                          }
                                        )}
                                        <FormControl fullWidth>
                                          <Button
                                            className={classes.buttonOutline}
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers2.push({
                                                ...initialSaleState
                                              })
                                            }
                                          >
                                            + Add Species
                                          </Button>
                                        </FormControl>
                                      </div>
                                    );
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    />
                    <FormControl fullWidth className={classes.bottomButtonBar}>
                      <div>
                        {reportError && (
                          <span className={classes.errorSpan}>
                            {reportError}
                          </span>
                        )}
                        <span>
                          You'll be able to review this information before
                          submitting
                        </span>
                      </div>
                      <div>
                        <span>Next: Review &amp; Submit Report</span>

                        {values.sales_order[0] &&
                          values.sales_order[0].items &&
                          values.sales_order[0].items.length > 0 && (
                            <Button
                              className={classes.proceedButton}
                              onClick={() => {
                                if (
                                  values.sales_order[0] &&
                                  values.sales_order[0].items &&
                                  values.sales_order[0].items.length > 0
                                ) {
                                  const reportObject = {
                                    newReport: isNewReport,
                                    originalReport: { ...savedFormValues },
                                    revisionReport: {
                                      ...savedFormValues,
                                      ...values,
                                      id: currentReportId
                                    }
                                  };
                                  if (!isNewReport) {
                                    reportObject.id = currentReportId;
                                  }
                                  if (isNewReport) {
                                    reportObject.originalReport = { ...values };
                                  }

                                  SchemaValidation.validate(values)
                                    .then(valid => {
                                      saveReport({ ...reportObject }).then(
                                        data => {
                                          setIsNewReport(false);
                                          setSavedFormValues({
                                            ...values
                                          });
                                          setCurrentReportId(data.id);
                                          setPageOffset(pageOffset + 1);
                                          scrollToTop();
                                          setReportError(null);
                                        }
                                      );
                                    })
                                    .catch(err => {
                                      setReportError(err.message);
                                    });
                                } else {
                                  setPageOffset(pageOffset + 1);
                                  scrollToTop();
                                  setReportError("");
                                }
                              }}
                            >
                              <span data-cy="submit">Save &amp; continue</span>
                            </Button>
                          )}
                      </div>
                    </FormControl>
                  </div>

                  {/*********************************************/}
                  {/***** PAGE 2: Review Report Information *****/}
                  {/*********************************************/}
                  <div
                    className={
                      pageOffset === 2 ? classes.visible : classes.hidden
                    }
                  >
                    <Button
                      className={classes.backButton}
                      onClick={() => {
                        setPageOffset(pageOffset - 1);
                        scrollToTop();
                      }}
                    >
                      &#8592; Back
                    </Button>
                    <CardHeader title underline style={{ marginBottom: "8px" }}>
                      <Typography variant="h6" style={{ marginBottom: "8px" }}>
                        New Vendor Report
                      </Typography>
                    </CardHeader>

                    <Typography variant="h6" className={classes.smallHeader}>
                      Date of Purchase{" "}
                      <span
                        className={classes.editButton}
                        onClick={() => setPageOffset(1)}
                      >
                        Edit
                      </span>
                    </Typography>
                    <p>
                      {moment(values.date_of_purchase).format(
                        "MM/DD/YYYY hh:mm a"
                      )}
                    </p>

                    <Typography variant="h6" className={classes.smallHeader}>
                      Fish Purchased From
                    </Typography>
                    <p>{values.first_purchased_from}</p>

                    <Divider style={{ margin: "1rem 0" }} />

                    {values.sales_order.length > 0 &&
                      values.sales_order[0].items &&
                      values.sales_order[0].items.length > 0 &&
                      values.sales_order.map((saleOrder, saleIndex) => {
                        return (
                          <div key={`sale-order-${saleIndex}`}>
                            {saleOrder.items.map((sale, itemIndex) => {
                              return (
                                <div
                                  key={`sale-${itemIndex}`}
                                  className={classes.fieldSpacingWrapper}
                                >
                                  <span>
                                    Species:{" "}
                                    {sale.species_id &&
                                      sale.species_id !== "none" &&
                                      species.find(spec => {
                                        return sale.species_id == spec.id;
                                      }).common_name}
                                    <Divider style={{ margin: "0.5rem 0" }} />
                                    {sale.total_count} Count &middot;{" "}
                                    {sale.total_weight_lbs} Pounds &middot; $
                                    {sale.price_per_lb_usd}/lb
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}

                    {!savedFormValues.verified && (
                      <FormControl
                        fullWidth
                        className={classes.bottomButtonBar}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="verified"
                                id="verified"
                                onClick={e => {
                                  setSavedFormValues({
                                    ...values,
                                    verified: e.target.checked
                                  });
                                }}
                              />
                            }
                            label="The above information is accurate"
                          ></FormControlLabel>
                        </FormGroup>
                      </FormControl>
                    )}

                    {savedFormValues.verified && (
                      <FormControl
                        fullWidth
                        className={classes.bottomButtonBar}
                      >
                        <div>
                          {reportError && (
                            <span className={classes.errorSpan}>
                              {reportError}
                            </span>
                          )}
                        </div>
                        <div>
                          <span>{`You won’t be able to edit or modify this report after you
                      submit`}</span>

                          <Button
                            className={classes.proceedButton}
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            Submit Report
                          </Button>
                        </div>
                      </FormControl>
                    )}
                  </div>
                </form>
              );
            }}
          />
          <div className={pageOffset === 3 ? classes.visible : classes.hidden}>
            <Button
              className={classes.backButton}
              onClick={() => {
                setPageOffset(0);
                scrollToTop();
              }}
            >
              &#8592; Back
            </Button>
            <CardHeader title underline style={{ marginBottom: "0rem" }}>
              <Typography variant="h6" style={{ marginBottom: "6px" }}>
                Report #{currentReportId}
              </Typography>
            </CardHeader>
            <CardBody>
              <ListItemText
                primary="Date of Purchase"
                secondary={
                  <Typography variant="subtitle1" color="textPrimary">
                    {moment(savedFormValues.date_of_purchase).format(
                      "MMMM Do, YYYY"
                    )}
                  </Typography>
                }
              />
              <ListItemText
                primary="Fish Purchased From"
                secondary={
                  <Typography variant="subtitle1" color="textPrimary">
                    {savedFormValues.first_purchased_from}
                  </Typography>
                }
              />
              <CustomDivider />
              {savedFormValues.sales_order &&
                savedFormValues.sales_order.length &&
                savedFormValues.sales_order[0].items &&
                savedFormValues.sales_order[0].items.length > 0 &&
                savedFormValues.sales_order.map((saleOrder, saleIndex) => {
                  return (
                    <div key={`sale-order-${saleIndex}`}>
                      {saleOrder.items.map((sale, itemIndex) => {
                        return (
                          <div
                            key={`sale-${itemIndex}`}
                            className={classes.fieldSpacingWrapper}
                          >
                            <span>
                              Species:{" "}
                              {sale.species_id &&
                                species.find(spec => {
                                  return sale.species_id == spec.id;
                                }).common_name}
                              <Divider style={{ margin: "0.5rem 0" }} />
                              Price per lb: &#36;
                              {sale.price_per_lb_usd} &middot; Total weight:{" "}
                              {sale.total_weight_lbs} lbs &middot; Total count:{" "}
                              {sale.total_count}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </CardBody>
          </div>
        </CardBody>
      </Card>
    </GridContainer>
  );
};

const StyledReports = withStyles(styles)(Reports);

const mapStateToProps = state => {
  return {
    account: state.account,
    isAuthenticated: state.user.isAuthenticated,
    vendorReports: state.vendorReports,
    species: state.species,
    fisherLicenses: state.fisherLicenses,
    speciesCategories: state.speciesCategories
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { addReport, updateReport, deleteReport, getReports, markVendorReportDone },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StyledReports);
