import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ListItemText,
  Typography
} from "../../components";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";

import AccountForm from "./AccountForm";
import { logoutUser } from "../../actions/auth";
import { bindActionCreators } from "redux";

import userGuide from "../../assets/pdfs/bottomfish_vendor_user_guide.pdf";

const Account = props => {
  const { business_license_number, full_name, logoutUser } = props;

  return (
    <Container>
      <Card>
        <CardHeader title underline>
          <Typography variant="h6" style={{ marginBottom: "8px" }}>
            Account Information
          </Typography>
          <Button color="primary" variant="outlined" onClick={logoutUser}>
            Log Out
          </Button>
        </CardHeader>
        <CardBody>
          <ListItemText
            primary="Vendor License Number"
            secondary={
              <Typography variant="subtitle1" color="textPrimary">
                {business_license_number}
              </Typography>
            }
          />
          <ListItemText
            primary="Name"
            secondary={
              <Typography variant="subtitle1" color="textPrimary">
                {full_name}
              </Typography>
            }
          />
          <ListItemText
            primary="Application Information"
            secondary={
              <>
                <Button color="primary" variant="outlined">
                  <Link
                    target="blank"
                    underline="none"
                    href={userGuide}
                    rel="noopener noreferrer"
                  >
                    User Guide
                  </Link>
                </Button>
              </>
            }
          />
          <AccountForm />
        </CardBody>
      </Card>
    </Container>
  );
};

function trimName(name) {
  //trim double white spaces ('  '), that occur when any part of the name is missing
  if (name.length === 0) return null;

  let prevChar = name[0];
  let i = 1;

  while (i < name.length) {
    if (name[i] === " " && prevChar === " ") {
      name = name.substring(0, i) + name.substring(i + 1);
    } else {
      prevChar = name[i];
      i++;
    }
  }

  return name;
}

const mapStateToProps = state => {
  const {
    business_license_number,
    first_name,
    middle_name,
    last_name
  } = state.account;
  let full_name = trimName(`${first_name} ${middle_name} ${last_name}`);

  return {
    business_license_number,
    full_name
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ logoutUser }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
