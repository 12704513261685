import { westpac } from "../services";
import { getApprovedReports } from "./dashboard";
import moment from "moment";

export const ADD_VENDOR_REPORT = "ADD_VENDOR_REPORT";
export const UPDATE_VENDOR_REPORT = "UPDATE_VENDOR_REPORT";
export const DELETE_VENDOR_REPORT = "DELETE_VENDOR_REPORT";
export const GET_VENDOR_REPORTS = "GET_VENDOR_REPORTS";
export const UPDATE_VENDOR_REPORT_DONE = "UPDATE_VENDOR_REPORT_DONE";

const uuidv1 = require("uuid/v1");

export const parseReportsData = data => {
  const parsedReports = data.vendor_reports.map(r => {
    return {
      id: r.id,
      created_at: r.created_at,
      updated_at: r.updated_at,
      submitted_at: r.submitted_at,
      is_approved: r.is_approved,
      date_of_purchase: moment(r.date_of_purchase).format("YYYY-MM-DDTHH:mm"),
      first_purchased_from: r.first_purchased_from,
      fishing_trip: {
        sales_order: parseSalesOrders(r.sales_order)
      },
      reason: r.reason,
      done: r.done
    };
  });

  return parsedReports;
};

function parseSalesOrders(sales_order) {
  return sales_order.map(sales_order => {
    return {
      created_at: sales_order.created_at,
      updated_at: sales_order.updated_at,
      vendor_report_id: sales_order.vendor_report_id,
      items: sales_order.items
    };
  });
}

export const getReports = () => {
  return dispatch => {
    return westpac
      .get("/vendor-reports")

      .then(({ data }) => {
        const parsedReports = parseReportsData(data);

        dispatch({
          type: GET_VENDOR_REPORTS,
          payload: {
            data: parsedReports
          }
        });

        dispatch(getApprovedReports(parsedReports));

        return parsedReports;
      })
      .catch(err => {
        throw err;
      });
  };
};

export const addReport = payload => {
  if (!navigator.onLine) {
    return dispatch => {
      const tempId = `temp-${uuidv1()}`;
      const parsedReport = parseReportsData({
        vendor_reports: [{ ...payload, id: tempId }]
      });
      dispatch({
        type: ADD_VENDOR_REPORT,
        payload: {
          report: { ...parsedReport[0] }
        }
      });
      return new Promise((resolve, reject) => {
        resolve({ ...parsedReport, id: tempId });
      });
    };
  }

  return dispatch => {
    return westpac
      .post("/vendor-report", { ...payload })
      .then(({ data }) => {
        const parsedReport = parseReportsData({
          vendor_reports: [{ ...data }]
        });
        dispatch({
          type: ADD_VENDOR_REPORT,
          payload: {
            report: { ...parsedReport[0] }
          }
        });
        return { ...parsedReport[0] };
      })
      .catch(err => {
        throw err;
      });
  };
};

export const updateReport = payload => {
  if (!navigator.onLine) {
    return dispatch => {
      dispatch({
        type: UPDATE_VENDOR_REPORT,
        payload: {
          report: payload.originalReport,
          revision: payload.revisionReport
        }
      });
      return new Promise((resolve, reject) => {
        resolve({ ...payload.revisionReport });
      });
    };
  }

  if (
    navigator.onLine &&
    typeof payload.id === "string" &&
    payload.id.includes("temp")
  ) {
    const payloadWithoutId = { ...payload.revisionReport };
    delete payloadWithoutId.id;

    return dispatch => {
      return westpac
        .post("/vendor-report", { ...payloadWithoutId })
        .then(({ data }) => {
          const parsedReport = parseReportsData({
            vendor_reports: [{ ...data }]
          });
          dispatch({
            type: ADD_VENDOR_REPORT,
            payload: {
              report: { ...parsedReport[0] }
            }
          });

          dispatch({
            type: DELETE_VENDOR_REPORT,
            payload: {
              id: payload.id
            }
          });
          return { ...parsedReport[0] };
        })
        .catch(err => {
          throw err;
        });
    };
  }

  return dispatch => {
    return westpac
      .put(`/vendor-report/${payload.id}`, { ...payload.revisionReport })
      .then(({ data }) => {
        const parsedReport = parseReportsData({
          vendor_reports: [{ ...data }]
        });
        dispatch({
          type: UPDATE_VENDOR_REPORT,
          payload: {
            report: payload.originalReport,
            revision: { ...parsedReport[0] }
          }
        });
        return { ...parsedReport[0] };
      })
      .catch(err => {
        throw err;
      });
  };
};

export const deleteReport = payload => ({
  type: DELETE_VENDOR_REPORT,
  payload: {
    id: payload.id
  }
});

export const markVendorReportDone = id => {
  return dispatch => {
    return westpac
      .put(`/vendor-report-done/${id}`)
      .then(() => {
        dispatch({
          type: UPDATE_VENDOR_REPORT_DONE,
          payload: {
            id
          }
        });
        return id;
      })
      .catch(err => {
        console.log("err", err);
        throw err;
      });
  };
};
