import React from "react";
import MuiInput from "@material-ui/core/Input";
import { Field } from "formik";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const CustomInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey["300"]}`,
    padding: "6px",
    fontSize: "1.2rem"
  }
}))(({ field, form, ...rest }) => {
  const { value, name, onChange, onBlur, pattern } = field;

  return (
    <MuiInput
      value={value}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      disableUnderline
      pattern={pattern}
      {...rest}
    />
  );
});

const Input = props => {
  return <Field {...props} component={CustomInput} style={{ marginTop: "0px" }}/>;
};

Input.propTypes = {
  //formik needs just a name, it handles the rest.
  name: PropTypes.string
};

export default Input;
