import { UPDATE_ACCOUNT } from "../actions/account";

const INITIAL_ACCOUNT_STATE = {
  business_license_number: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  contact_information_address_street: "",
  contact_information_address_street_secondary: "",
  contact_information_address_city: "",
  contact_information_address_postal_code: "",
  contact_information_email_address: "",
  contact_information_phone_number: "",
  vessel_license_number: ""
};

export default function vendorReportsReducer(
  state = INITIAL_ACCOUNT_STATE,
  action = {}
) {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return { ...state, ...action.payload.account };
    default:
      return state;
  }
}
