import React from "react";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

import { Button, Typography } from "../../components";
import { inherits } from "util";

const BoardHeader = withStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${theme.spacing(1)}px 0px`,
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    marginBottom: "1rem"
  },
  arrow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: `0px ${theme.spacing(2)}px`,
    "&:last-child": {
      padding: `0px ${theme.spacing(2)}px`
    },
    fontSize: `0.6rem`
  },
  hidden: {
    visibility: "hidden",
    pointerEvents: "none"
  }
}))(
  ({
    classes,
    hideRightArrow,
    hideLeftArrow,
    onRightArrowClick,
    onLeftArrowClick,
    text
  }) => {
    const rightArrowClasses = cn({
      [classes.arrow]: true,
      [classes.hidden]: hideRightArrow
    });

    const leftArrowClasses = cn({
      [classes.arrow]: true,
      [classes.hidden]: hideLeftArrow
    });

    return (
      <div className={classes.container}>
        <div className={leftArrowClasses} onClick={onLeftArrowClick}>
          <span>&#9664;</span>
        </div>
        <Typography variant="h6">{text}</Typography>
        <div className={rightArrowClasses} onClick={onRightArrowClick}>
          &#9654;
        </div>
      </div>
    );
  }
);

BoardHeader.propTypes = {
  text: PropTypes.string,
  hideRightArrow: PropTypes.bool,
  hideLeftArrow: PropTypes.bool,
  onRightArrowClick: PropTypes.func,
  onLeftArrowClick: PropTypes.func
};

export default BoardHeader;
