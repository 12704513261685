import React from "react";
import { withStyles } from "@material-ui/styles";
import MuiGrid from "@material-ui/core/Grid";
import cn from "classnames";

const styles = theme => ({});

const GridContainer = ({ classes, className, ...rest }) => {
  const gridClasses = cn({
    [classes.grid]: true,
    [className]: className !== undefined
  });

  return <MuiGrid container {...rest} className={gridClasses} />;
};

export default withStyles(styles)(GridContainer);
