import React from 'react';
import { withStyles } from '@material-ui/styles';
import cn from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  cardHeader: {
    boxSizing: 'border-box',
    padding: `${theme.spacing(2)}px 0`
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  title: {
    textAlign: 'center'
  }
});

const CardHeader = ({
  children,
  classes,
  className,
  title,
  underline,
  ...rest
}) => {
  const cardHeaderClasses = cn({
    [classes.cardHeader]: true,
    [classes.underline]: underline,
    [classes.title]: title,
    [className]: className !== undefined
  });

  return (
    <div {...rest} className={cardHeaderClasses}>
      {children}
    </div>
  );
};

CardHeader.propTypes = {
  className: PropTypes.string,
  underline: PropTypes.bool
};

export default withStyles(styles)(CardHeader);
