import React from "react";
import { withStyles } from "@material-ui/styles";
import cn from "classnames";
import PropTypes from "prop-types";

export const PeriodTabs = withStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "stretch",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderRadius: theme.shape.borderRadius
  }
}))(({ classes, children, ...rest }) => {
  return (
    <div className={classes.container} {...rest}>
      {children}
    </div>
  );
});

export const Tab = withStyles(theme => ({
  tab: {
    flex: "1 1 50%",
    padding: `${theme.spacing(1)}px 0px`,
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: "transparent",
    color: theme.palette.grey["400"],
    borderRight: `1px solid ${theme.palette.grey["500"]}`,
    "&:last-child": {
      borderRight: "none"
    },
    fontSize: "1rem"
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  }
}))(({ active, classes, children, ...rest }) => {
  const tabClasses = cn({
    [classes.tab]: true,
    [classes.active]: active
  });

  return (
    <div className={tabClasses} {...rest}>
      {children}
    </div>
  );
});

Tab.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func
};
