import * as Yup from "yup";

const COUNT_ERROR = "Count must be a positive whole number";
const DATE_OF_PURCHASE_ERROR = "Date of purchase is required";
const FIRST_PURCHASED_FROM_ERROR = "Fishing license is required";
const PRICE_PER_LB_ERROR =
  "Price per lb must be a positive number with up two decimal places";
const SPECIES_ERROR = "Species is required";
const WEIGHT_ERROR = "Weight must be a positive number with up to two decimal places";

const SalesOrderItemsSchema = Yup.object().shape({
  species_id: Yup.number(SPECIES_ERROR)
    .typeError(SPECIES_ERROR)
    .positive(SPECIES_ERROR)
    .integer(SPECIES_ERROR)
    .required(SPECIES_ERROR),
  total_count: Yup.number(COUNT_ERROR)
    .typeError(COUNT_ERROR)
    .positive(COUNT_ERROR)
    .integer(COUNT_ERROR)
    .required(COUNT_ERROR),
  total_weight_lbs: Yup.number(WEIGHT_ERROR)
    .typeError(WEIGHT_ERROR)
    .positive(WEIGHT_ERROR)
    // TODO: Needs to validate 2 decimal places
    // .integer(WEIGHT_ERROR)
    .required(WEIGHT_ERROR),
  price_per_lb_usd: Yup.number(PRICE_PER_LB_ERROR)
    .typeError(PRICE_PER_LB_ERROR)
    .positive(PRICE_PER_LB_ERROR)
    // TODO: Needs to validate 2 decimal places
    // .integer(PRICE_PER_LB_ERROR)
    .required(PRICE_PER_LB_ERROR)
});

const SalesOrderSchema = Yup.object().shape({
  items: Yup.array()
    .of(SalesOrderItemsSchema)
    .ensure()
});

export const SchemaValidation = Yup.object().shape({
  sales_order: Yup.array().of(SalesOrderSchema),
  date_of_purchase: Yup.string(DATE_OF_PURCHASE_ERROR)
    .typeError(DATE_OF_PURCHASE_ERROR)
    .trim()
    .required(DATE_OF_PURCHASE_ERROR),
  first_purchased_from: Yup.string(FIRST_PURCHASED_FROM_ERROR)
    .typeError(FIRST_PURCHASED_FROM_ERROR)
    .trim()
    .required(FIRST_PURCHASED_FROM_ERROR)
});

export const scrollToTop = () => {
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
};
