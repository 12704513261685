import * as React from "react";
import MuiInputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  shrink: {
    color: theme.palette.common.black,
    fontSize: "1.2rem"
  },
  root: {
    position: "relative"
  }
});

const InputLabel = ({ classes, children, shrink = true, ...rest }) => {
  return (
    <MuiInputLabel classes={classes} shrink={shrink} {...rest}>
      {children}
    </MuiInputLabel>
  );
};

export default withStyles(styles)(InputLabel);
