import React from "react";
import Select from "react-select";

const SelectSearch = ({ field, form, options, isMulti = false, value }) => {
  const onChange = option => {
    if (isMulti && !option) {
      form.setFieldValue(field.name, []);
    } else {
      form.setFieldValue(
        field.name,
        isMulti ? option.map(item => item.value) : option.value
      );
    }
  };

  const getValue = () => {
    if (value === null) {
      return null;
    }
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
};

export default SelectSearch;
