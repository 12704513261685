import { GET_FISHER_LICENSES } from "../actions/fishers";

const INITIAL_FISHER_LICENSES_STATE = [];

export default function fishersReducer(
  state = INITIAL_FISHER_LICENSES_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_FISHER_LICENSES:
      return action.payload.data.fisher_licenses;
    default:
      return state;
  }
}
