import * as Yup from "yup";

export const password = Yup.string()
  .test("validatePassword", validatePassword)
  .test("Password must be provided.", function(password) {
    const { path, createError } = this;
    const { re_entered_password } = this.parent;

    if (!password && re_entered_password) {
      return createError({
        path,
        message: "Password must be provided."
      });
    }

    return true;
  });

export const re_entered_password = Yup.string().test(
  "Reenter Password must be provided",
  function(re_entered_password) {
    const { path, createError } = this;
    const { password } = this.parent;

    if (password && !re_entered_password) {
      return createError({
        path,
        message: "Reenter Password must be provided."
      });
    }

    if (password && password !== re_entered_password) {
      return createError({
        path,
        message: "Must match password."
      });
    }

    return true;
  }
);

export function validatePassword(password) {
  if (password === undefined) return true;

  const { path, createError } = this;

  const symbolRegExp = /[!-/:-@[-`{-~]/;
  const digitRegExp = /\d/;
  const lowercaseCharacterRegExp = /[a-z]/;
  const uppercaseCharacterRegExp = /[A-Z]/;

  const errors = [];

  if (password.length < 10) {
    errors.push(new Error("Must contain at least 10 characters."));
  }

  if (!symbolRegExp.test(password)) {
    errors.push(new Error("Must contain at least one symbol."));
  }
  if (!digitRegExp.test(password)) {
    errors.push(new Error("Must contain at least one digit."));
  }
  if (!lowercaseCharacterRegExp.test(password)) {
    errors.push(new Error("Must contain at least one lowercase character."));
  }
  if (!uppercaseCharacterRegExp.test(password)) {
    errors.push(new Error("Must contain at least one uppercase character."));
  }

  if (errors.length > 1) {
    let failureMessage = errors.reduce(
      (message, error) => message + error.message + ";", //delimit multiple errors with ';'
      ""
    );

    failureMessage = failureMessage.substring(0, failureMessage.length - 1); //remove last ;
    return createError({ path, message: failureMessage });
  }

  return true;
}
