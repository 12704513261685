import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "@reach/router";
import { withStyles } from "@material-ui/styles";

import Header from "../Header/Header";

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    maxWidth: theme.container.maxWidth,
    margin: "0 auto"
  },
  container: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    width: "100%",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    left: "auto",
    right: 0,
    marginBottom: "0.5rem"
  }
});

const LinkTab = withStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(16),
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      minWidth: "auto"
    }
  }
}))(props => <Tab {...props} component={Link} />);

const StyledTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.light,
    height: "3px"
  }
}))(props => <Tabs {...props} />);

function getTab(pathname) {
  const tab = pathname.split("/")[2];

  return {
    reports: 0,
    dashboard: 1,
    account: 2
  }[tab];
}

const NavTabs = props => {
  const { classes, pathname } = props;

  const tab = getTab(pathname);

  return (
    <div className={classes.container}>
      <Header />
      <AppBar className={classes.appBar} elevation={0} position="relative">
        <StyledTabs value={tab}>
          <LinkTab to="reports" label="Reports" />
          <LinkTab to="dashboard" label="Dashboard" />
          <LinkTab to="account" label="Account" />
        </StyledTabs>
      </AppBar>
    </div>
  );
};

export default withStyles(styles)(NavTabs);
