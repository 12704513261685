import React from 'react';
import MuiTypography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import cn from 'classnames';

const styles = theme => ({
  primary: {
    color: theme.palette.primary.main
  }
});

const Typography = ({ className, classes, color, variant, style, ...rest }) => {
  const typographyClasses = cn({
    [classes[color]]: color !== undefined,
    [className]: className !== undefined
  });

  return (
    <MuiTypography
      className={typographyClasses}
      variant={variant}
      style={style}
      {...rest}
    />
  );
};

Typography.propTypes = {
  color: PropTypes.oneOf(['primary']),
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'h5',
    'h6',
    'body1',
    'body2',
    'subtitle1',
    'subtitle2'
  ]),
  style: PropTypes.object
};

export default withStyles(styles)(Typography);
