import { westpac } from "../services";

export const GET_FISHER_LICENSES = "GET_FISHER_LICENSES";

export const getFisherLicenses = () => {
  return dispatch => {
    return westpac
      .get("/fisher-licenses")

      .then(({ data }) => {
        dispatch({
          type: GET_FISHER_LICENSES,
          payload: {
            data
          }
        });
      })
      .catch(err => {
        throw err;
      });
  };
};