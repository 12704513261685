import { publicApiService } from "../services";
import { useEffect, useState } from "react";

const CLIENT_ID = process.env.REACT_APP_CLIENT_APP_ID;

export default function useFields() {
  const [fields, setFields] = useState(new Map());

  useEffect(() => {
    const fetchFields = async () => {
      const { data } = await publicApiService.get("/fields", {
        params: {
          client_id: CLIENT_ID
        }
      });

      setFields(new Map(data.map(({ field, body }) => [field, body])));
    };

    fetchFields();
  }, []);

  return fields;
}
