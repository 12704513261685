import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import theme from "./components/theme";
import { store } from "./store";
import "./assets/global.css";

import App from "./layouts/App";
import Login from "./layouts/Login";

import { ProtectedRoute } from "./components";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Login path="/*" />
        <ProtectedRoute Component={App} path="/app/*" />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
