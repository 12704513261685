import axios from "axios";
import { store } from "./store";
import { logoutUser } from "./actions/auth";

const config = {
  baseURL: `${process.env.REACT_APP_API_URL}/api`
};

export const publicApiService = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

export const westpac = axios.create(config);

westpac.interceptors.request.use(config => {
  const authToken = window.localStorage.getItem("access_token");
  if (authToken) {
    return {
      ...config,
      headers: {
        // Use the jwt saved in local storage
        Authorization: `Bearer ${authToken}`
      }
    };
  } else {
    return config;
  }
});

westpac.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      const { status } = error.response;

      if (status === 401) {
        store.dispatch(logoutUser());
      }
    }

    return Promise.reject(error);
  }
);
