import { westpac } from "../services";

export const GET_SPECIES_CATEGORIES = "GET_SPECIES_CATEGORIES";

export const getSpeciesCategories = () => {
  return dispatch => {
    return westpac.get("/species-categories").then(({ data }) => {
      dispatch({
        type: GET_SPECIES_CATEGORIES,
        payload: { speciesCategories: data.species_categories }
      });
    });
  };
};
