import { combineReducers } from "redux";

import accountReducer from "./account";
import vendorReportsReducer from "./vendor-reports";
import speciesReducer from "./species";
import speciesCategoriesReducer from "./species-categories";
import userReducer from "./user";
import fishersReducer from "./fishers";
import { LOGOUT_USER } from "../actions/auth";
import dashboardReducer from "./dashboard";
import metricsReducer from "./market-metrics";

const appReducer = combineReducers({
  account: accountReducer,
  dashboard: dashboardReducer,
  vendorReports: vendorReportsReducer,
  species: speciesReducer,
  speciesCategories: speciesCategoriesReducer,
  user: userReducer,
  fisherLicenses: fishersReducer,
  marketMetrics: metricsReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
