import { publicApiService } from "../services";
import { westpac } from "../services";
import moment from "moment";

export const GET_LAST_YEARS_CATCH_TOTALS = "GET_LAST_YEAR_CATCH_TOTALS";
export const GET_MARKET_METRICS = "GET_MARKET_METRICS";
export const GET_LEADERBOARD_RANK = "GET_LEADERBOARD_RANK";

export const getMarketMetrics = () => {
  return dispatch => {
    return publicApiService.get("platform_metrics").then(({ data }) => {
      dispatch({ type: GET_MARKET_METRICS, payload: { data } });
    });
  };
};

export const getLastYearsCatchTotals = () => {
  return dispatch => {
    const start = moment().startOf("year").subtract(1, "year");
    const end = moment().startOf("year");

    return publicApiService
      .get("/platform_metrics", {
        params: {
          after: start.valueOf(),
          before: end.valueOf()
        }
      })
      .then(({ data }) => {
        const totals = {
          Bottomfishing: parseFloat(
            process.env.REACT_APP_FALLBACK_CATCH_TOTAL_BOTTOMFISHING
          ),
          Spearfishing: parseFloat(
            process.env.REACT_APP_FALLBACK_CATCH_TOTAL_SPEARFISHING
          ),
          Trolling: parseFloat(
            process.env.REACT_APP_FALLBACK_CATCH_TOTAL_TROLLING
          )
        };

        if (!data.reported_catches) {
          dispatch({ type: GET_LAST_YEARS_CATCH_TOTALS, totals: totals });
        }

        const fetchedTotals = data.reported_catches
          .slice(0, -1)
          .reduce((aggregate, metric) => {
            const methodTotals =
              metric.species_catch_totals[
                metric.species_catch_totals.length - 1
              ].total_weight_lbs;
            if (methodTotals) {
              aggregate[metric.fishing_method.type] =
                metric.species_catch_totals[
                  metric.species_catch_totals.length - 1
                ].total_weight_lbs;
            }
            return aggregate;
          }, totals);
        dispatch({ type: GET_LAST_YEARS_CATCH_TOTALS, totals: fetchedTotals });
      });
  };
};

export const getLeaderboardRank = () => {
  return dispatch => {
    return westpac.get("leaderboards/vendor").then(({ data }) => {
      dispatch({ type: GET_LEADERBOARD_RANK, payload: { data } });
    });
  };
};
