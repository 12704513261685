import axios from "axios";

export const VERIFY_CREDENTIALS = "VERIFY_CRENDENTIALS";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const DISABLE_RESET_PASSWORD_PROMPT = "DISABLE_RESET_PASSWORD_PROMPT";

export const verifyCredentials = (business_license_number, password) => {
  return dispatch => {
    dispatch({ type: VERIFY_CREDENTIALS });
    return axios
      .post("/vendor-login", {
        business_license_number,
        password
      })
      .then(response => {
        window.localStorage.setItem("access_token", response.data.access_token);
        return response;
      });
  };
};

export const loginUser = ({ isFirstLogin }) => ({
  type: LOGIN_USER,
  payload: {
    user: {
      isFirstLogin
    }
  }
});

export const logoutUser = () => {
  window.localStorage.removeItem("access_token");
  return {
    type: LOGOUT_USER
  };
};

export const disablePasswordResetPrompt = () => ({
  type: DISABLE_RESET_PASSWORD_PROMPT
});
