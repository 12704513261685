import React from "react";
import { Typography } from "../../components";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import cn from "classnames";

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: 900,
    padding: theme.spacing(0.5)
  },
  text: {
    fontWeight: 900,
    color: theme.palette.primary.contrastText
  },
  fixed: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999
  },
  static: {
    position: "static"
  },
  absolute: {
    position: "absolute"
  },
  relative: {
    position: "relative"
  },
  sticky: {
    position: "sticky"
  }
});

const Header = ({ classes, position, className, ...reset }) => {
  const headerClasses = cn({
    [classes.header]: true,
    [classes[position]]: position !== undefined,
    [className]: className !== undefined
  });
  return (
    <div className={headerClasses}>
      <Typography className={classes.text} variant="subtitle2">
        Fish Vendor
      </Typography>
    </div>
  );
};

Header.propTypes = {
  position: PropTypes.oneOf([
    "fixed",
    "static",
    "absolute",
    "relative",
    "sticky"
  ])
};

export default withStyles(styles)(Header);
