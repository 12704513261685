import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { bindActionCreators } from "redux";

import {
  Card,
  Container,
  Divider,
  Typography,
  GridContainer,
  GridItem
} from "../../components";
import { Bar, Pie, Progress } from "@sudokrew/wespac-components";
import { PeriodTabs, Tab } from "./PeriodTabs";
import BoardHeader from "./BoardHeader";
import SpeciesTable from "./SpeciesTable";
import aggregator from "./aggregator";
import peek from "./peek";
import {
  updatePeriod,
  incrementStartDate,
  decrementStartDate
} from "../../actions/dashboard";
import { findQuarter, QUARTERS } from "./utils";
import {
  VictoryChart,
  VictoryAxis,
  VictoryContainer as GraphContainer,
  VictoryLegend as Legend
} from "victory";

const PROGRESS_SWATCHES = {
  Bottomfishing: ["#6DAB32", "#E0EECF", "#586947"],
  Trolling: ["#4195C6", "#D3E3E3", "#4C6371"],
  Spearfishing: ["#357473", "#D2E9F4", "#485A5A"]
};

/**
 * @param {number} number
 */
const toPercentage = number => {
  if (number == null) {
    return "";
  }

  return number.toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0
  });
};

function formatPlacement(placement) {
  if (placement == undefined) {
    return "–";
  }
  return moment.localeData().ordinal(placement);
}

/**
 *
 * @param {number | null} number
 */
function formatNumberToString(number) {
  if (number == null) {
    return "-";
  }

  return number.toLocaleString();
}

const styles = theme => ({
  boardBody: {
    padding: `0px ${theme.spacing(1)}px`
  },
  cardBody: {
    padding: `${theme.spacing(2)}px`
  },
  innerCard: {
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px 0`,
    paddingBottom: `${theme.spacing(3)}px`
  },
  boxBorderStyles: {
    border: "1px solid #D8D8D8",
    borderRadius: "2px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 3px 0 rgba(0,0,0,0.1)",
    padding: "3rem 0"
  },
  totalsContainer: { padding: `${theme.spacing(1)}px 0px` }
});

function renderSpeciesTables(table) {
  let entries = [];
  Object.keys(table).forEach(key => {
    entries = [...entries, ...table[key]];
  });

  return <SpeciesTable rows={entries} />;
}

function formatDate(date, period) {
  if (period === "year") {
    return date.year();
  }

  if (period === "quarter") {
    const quarter = findQuarter(date.month());
    const monthsInQuarter = QUARTERS[quarter];
    const beginning = moment({ month: monthsInQuarter[0] }).format("MMM");
    const end = moment({ month: monthsInQuarter[2] }).format("MMM");
    const year = date.year();

    return `${beginning}-${end} ${year}`;
  }

  if (period === "month") {
    return `${date.format("MMM")} ${date.year()}`;
  }
}

function findSpeciesName(speciesList, speciesId) {
  const speciesFound = speciesList.find(fish => fish.id == speciesId);
  if (speciesFound) return speciesFound.common_name;
  return "-";
}

function renderPieChart(options) {
  return (
    <Box
      component="div"
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Pie
        width={120}
        height={120}
        radius={60}
        containerComponent={
          <GraphContainer responsive={false} height={120} width={120} />
        }
        data={Object.keys(options.catchCount).map(c => {
          return {
            x: c,
            y: options.catchCount[c]
          };
        })}
        style={{
          data: {
            fill: ({ datum }) => {
              switch (datum.x) {
                case "Bottomfishing":
                  return "#56AE00";
                case "Spearfishing":
                  return "#0D7674";
                case "Trolling":
                default:
                  return "#0096CB";
              }
            }
          }
        }}
      />
      <Legend
        width={150}
        height={100}
        containerComponent={
          <GraphContainer responsive={false} style={{ paddingTop: "1rem" }} />
        }
        orientation="vertical"
        rowGutter={-5}
        data={[
          {
            name: `${toPercentage(
              options.catchCount["Spearfishing"]
            )} Spearfishing`,
            symbol: { fill: "#0D7674", type: "square" }
          },
          {
            name: `${toPercentage(options.catchCount["Trolling"])} Trolling`,
            symbol: { fill: "#0096CB", type: "square" }
          },
          {
            name: `${toPercentage(
              options.catchCount["Bottomfishing"]
            )} Bottomfishing`,
            symbol: { fill: "#56AE00", type: "square" }
          }
        ]}
      />
    </Box>
  );
}

const Dashboard = ({
  approvedReports,
  classes,
  updatePeriod,
  incrementStartDate,
  decrementStartDate,
  period,
  startDate,
  speciesInfo,
  marketMetrics,
  ...rest
}) => {
  const {
    totalPoundsBought,
    totalSales,
    totalPoundsSold,
    salesBySpeciesOfFish,
    totalTripCount,
    purchasedPoundsByMonth,
    purchasesByFisher,
    marketAveragesBySpecies,
    metricsBySpeciesOfFish,
    lastYearsCatchTotals
  } = aggregator(
    approvedReports,
    startDate,
    period,
    marketMetrics.average_market_prices,
    speciesInfo,
    marketMetrics.last_years_catch_totals
  );

  const USER_DASHBOARD = "USER_DASHBOARD";
  const COMMUNITY_DASHBOARD = "COMMUNITY_DASHBOARD";
  const speciesTables = salesBySpeciesOfFish.reduce((table, species) => {
    const { category, common_name } = speciesInfo.find(
      info => info.id == species.id
    );

    if (table.hasOwnProperty(category)) {
      table[category].push({ ...species, common_name });
    } else {
      table[category] = [{ ...species, common_name }];
    }

    return table;
  }, {});

  const canMoveForward = peek.forward(approvedReports, startDate, period);
  const canMoveBackwards = peek.backward(approvedReports, startDate, period);

  const formattedDate = formatDate(startDate, period);

  const [activeTab, setActiveTab] = useState(COMMUNITY_DASHBOARD);

  let topSpeciesByWeight = [...salesBySpeciesOfFish].sort(
    (a, b) => b.total_lbs - a.total_lbs
  );
  if (topSpeciesByWeight.length > 5) {
    // Shortens the list of top 5 species caught
    topSpeciesByWeight = topSpeciesByWeight.slice(0, 5);
  }

  let purchasesByFisherList = Object.entries(purchasesByFisher).sort(
    (a, b) => b[1] - a[1]
  );
  if (purchasesByFisherList.length > 5) {
    // Shortens the list of top fishers bought from to 5
    purchasesByFisherList = purchasesByFisherList.slice(0, 5);
  }

  let purchasedPoundsByMonthGraphValues = [
    { label: "Jan", value: 0 },
    { label: "Feb", value: 0 },
    { label: "Mar", value: 0 },
    { label: "Apr", value: 0 },
    { label: "May", value: 0 },
    { label: "June", value: 0 },
    { label: "July", value: 0 },
    { label: "Aug", value: 0 },
    { label: "Sept", value: 0 },
    { label: "Oct", value: 0 },
    { label: "Nov", value: 0 },
    { label: "Dec", value: 0 }
  ];

  Object.keys(purchasedPoundsByMonth).forEach(monthKey => {
    purchasedPoundsByMonthGraphValues[+monthKey - 1].value =
      purchasedPoundsByMonth[monthKey];
  });

  const catchTotalsByFishingMethod = {
    Bottomfishing: 0,
    Spearfishing: 0,
    Trolling: 0,
    Total: 0
  };

  if (marketMetrics) {
    marketMetrics.reported_catches.forEach(catchMetricByMethod => {
      if (catchMetricByMethod.fishing_method === null) {
        catchTotalsByFishingMethod.Total =
          catchMetricByMethod.species_catch_totals[
            catchMetricByMethod.species_catch_totals.length - 1
          ].total_weight_lbs;
      } else {
        catchTotalsByFishingMethod[catchMetricByMethod.fishing_method.type] =
          catchMetricByMethod.species_catch_totals[
            catchMetricByMethod.species_catch_totals.length - 1
          ].total_weight_lbs;
      }
    });
  }

  const lastYearCatchTotals = {
    Bottomfishing: lastYearsCatchTotals.Bottomfishing,
    Spearfishing: lastYearsCatchTotals.Spearfishing,
    Trolling: lastYearsCatchTotals.Trolling
  };

  const catchCountPercentages = {
    Bottomfishing:
      catchTotalsByFishingMethod.Bottomfishing /
      catchTotalsByFishingMethod.Total,
    Spearfishing:
      catchTotalsByFishingMethod.Spearfishing /
      catchTotalsByFishingMethod.Total,
    Trolling:
      catchTotalsByFishingMethod.Trolling / catchTotalsByFishingMethod.Total
  };

  const leaderboardPlacement =
    marketMetrics.leaderboard && marketMetrics.leaderboard.placement
      ? marketMetrics.leaderboard.placement
      : null;

  return (
    <Container>
      <PeriodTabs style={{ marginBottom: "1rem" }}>
        <Tab
          active={activeTab === COMMUNITY_DASHBOARD}
          onClick={() => setActiveTab(COMMUNITY_DASHBOARD)}
        >
          Community Dashboard
        </Tab>
        <Tab
          active={activeTab === USER_DASHBOARD}
          onClick={() => setActiveTab(USER_DASHBOARD)}
        >
          My Dashboard
        </Tab>
      </PeriodTabs>
      {activeTab === COMMUNITY_DASHBOARD && (
        <>
          <GridContainer justify="center" alignItems="stretch">
            <GridItem
              xs={7}
              className={classes.boxBorderStyles}
              style={{
                textAlign: "left",
                paddingLeft: "1rem",
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "space-evenly"
              }}
            >
              <Typography variant="h5">
                <strong>
                  {formatNumberToString(catchTotalsByFishingMethod.Total)}
                </strong>
              </Typography>
              <Typography variant="p">{`Total Pounds Caught for ${moment().format(
                "YYYY"
              )}`}</Typography>

              <Divider style={{ margin: "1rem 0 1rem -1rem" }} />
              <Typography variant="h5">Total Pounds Caught</Typography>
              <dl
                style={{
                  display: "grid",
                  gridColumnGap: 8,
                  gridTemplateColumns: "auto 1fr"
                }}
              >
                {[
                  [
                    formatNumberToString(
                      catchTotalsByFishingMethod.Bottomfishing
                    ),
                    "Bottomfishing"
                  ],
                  [
                    formatNumberToString(catchTotalsByFishingMethod.Trolling),
                    "Trolling"
                  ],
                  [
                    formatNumberToString(
                      catchTotalsByFishingMethod.Spearfishing
                    ),
                    "Spearfishing"
                  ]
                ].map(d => {
                  return (
                    <>
                      <dt style={{ margin: "0.117rem 0" }}>
                        <strong>{d[0]}</strong>
                      </dt>
                      <dd style={{ margin: "0.117rem 0", textAlign: "left" }}>
                        {d[1]}
                      </dd>
                    </>
                  );
                })}
              </dl>
            </GridItem>
            <GridItem
              xs={5}
              className={classes.boxBorderStyles}
              style={{ textAlign: "center" }}
            >
              {renderPieChart({
                catchCount: catchCountPercentages
              })}
            </GridItem>
            <p style={{ width: "100%", padding: "0 0.5rem" }}>
              <span style={{ fontSize: 20, fontWeight: 300 }}>
                Total Pounds Caught Year to Date
              </span>
            </p>
            <React.Fragment>
              {Object.entries(lastYearCatchTotals).map(
                ([methodName, lastYearsTotal]) => {
                  const currentYearsTotal =
                    catchTotalsByFishingMethod[methodName];
                  return (
                    <GridItem
                      key={`${methodName}_${lastYearsTotal}`}
                      xs={12}
                      className={classes.boxBorderStyles}
                      style={{
                        padding: "1rem",
                        marginBottom: "1rem",
                        display: "flex"
                      }}
                    >
                      <div
                        style={{
                          marginRight: "0.75rem",
                          alignSelf: "center",
                          flex: "0 0 110px"
                        }}
                      >
                        <span style={{ fontSize: 19, fontWeight: 300 }}>
                          {methodName}
                        </span>
                      </div>
                      <div>
                        <div>
                          <Progress
                            value={currentYearsTotal}
                            max={lastYearsTotal}
                            colorScale={PROGRESS_SWATCHES[methodName]}
                          ></Progress>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <div style={{ textAlign: "left" }}>
                            <strong>
                              {formatNumberToString(currentYearsTotal)}
                            </strong>
                            <br />
                            Caught
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <strong>
                              {formatNumberToString(lastYearsTotal)}
                            </strong>
                            <br />
                            Last year's total
                          </div>
                        </div>
                      </div>
                    </GridItem>
                  );
                }
              )}
            </React.Fragment>
          </GridContainer>
        </>
      )}
      {activeTab === USER_DASHBOARD && (
        <>
          <Container>
            <Card className={classes.board}>
              <Card className={classes.innerCard}>
                <PeriodTabs style={{ margin: "1rem" }}>
                  <Tab
                    active={period === "month"}
                    onClick={() => updatePeriod("month")}
                  >
                    Month
                  </Tab>
                  <Tab
                    active={period === "quarter"}
                    onClick={() => updatePeriod("quarter")}
                  >
                    Quarter
                  </Tab>
                  <Tab
                    active={period === "year"}
                    onClick={() => updatePeriod("year")}
                  >
                    Year
                  </Tab>
                </PeriodTabs>
                <BoardHeader
                  text={formattedDate}
                  onLeftArrowClick={() => decrementStartDate(startDate, period)}
                  onRightArrowClick={() =>
                    incrementStartDate(startDate, period)
                  }
                  hideLeftArrow={!canMoveBackwards}
                  hideRightArrow={!canMoveForward}
                />
                <GridContainer wrap="nowrap" justify="space-evenly" spacing={3}>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {formatNumberToString(totalTripCount)}
                    </Typography>
                    Sales Reported
                  </GridItem>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {formatNumberToString(totalPoundsBought)}
                    </Typography>
                    Pounds Purchased
                  </GridItem>
                  <GridItem style={{ textAlign: "center" }}>
                    <Typography variant="h5">
                      {`$${formatNumberToString(totalSales)}`}
                    </Typography>
                    of Fish Purchased
                  </GridItem>
                </GridContainer>
              </Card>

              <div className={classes.cardBody}>
                <Typography variant="h5" style={{ margin: "1rem 0 0 0" }}>
                  Fish Purchased Per Month
                </Typography>
                <VictoryChart domainPadding={{ x: 20 }} height={300}>
                  <VictoryAxis
                    style={{
                      tickLabels: { fontWeight: 100, fontSize: 11, padding: 7 }
                    }}
                  />
                  <VictoryAxis
                    dependentAxis
                    style={{
                      tickLabels: {
                        fontWeight: 100,
                        fontSize: 13,
                        lineHeight: 29,
                        padding: 11
                      }
                    }}
                  />
                  {purchasedPoundsByMonthGraphValues.map(
                    (month, monthIndex) => {
                      return (
                        <Bar
                          key={`month-bar-${monthIndex}`}
                          barRatio={3}
                          style={{ data: { fill: "#133BAF" } }}
                          data={[
                            {
                              x: month.label,
                              y: month.value
                            }
                          ]}
                        />
                      );
                    }
                  )}
                </VictoryChart>
              </div>

              <div className={classes.cardBody}>
                <div
                  style={{ padding: "1rem", marginBottom: "1.5rem" }}
                  className={classes.boxBorderStyles}
                >
                  <Typography
                    variant="h5"
                    style={{ fontSize: "2rem", fontWeight: "700" }}
                  >
                    {formatPlacement(leaderboardPlacement)}
                  </Typography>
                  <Typography variant="body1">
                    Most Pounds Purchased This Week
                  </Typography>
                </div>
                <div
                  style={{ padding: "1rem" }}
                  className={classes.boxBorderStyles}
                >
                  <Typography variant="h5">
                    Top Fishers Purchased From
                  </Typography>

                  {purchasesByFisherList.map((sale, saleIndex) => {
                    let rankLabel = ["1st", "2nd", "3rd", "4th", "5th"];
                    return (
                      <GridContainer
                        key={`fisher-purchased-from-${sale[0]}-${saleIndex}}`}
                      >
                        <GridItem xs={2}>{rankLabel[saleIndex]} </GridItem>
                        <GridItem xs={7}>{sale[0]}</GridItem>
                        <GridItem xs={3}>
                          {formatNumberToString(sale[1])} lbs
                        </GridItem>
                      </GridContainer>
                    );
                  })}
                </div>
              </div>

              <br />
              <div>
                <div className={classes.totalsContainer}></div>
                <Divider />
                {salesBySpeciesOfFish.length > 0 && (
                  <div className={classes.cardBody}>
                    <h5>
                      <Typography align="center" variant="subtitle2">
                        Sales By Species of Fish
                      </Typography>
                    </h5>
                    {renderSpeciesTables(speciesTables)}
                  </div>
                )}
              </div>
            </Card>
          </Container>
        </>
      )}
    </Container>
  );
};

const StyledDashboard = withStyles(styles)(Dashboard);

const mapStateToProps = state => ({
  period: state.dashboard.period,
  startDate: state.dashboard.startDate,
  approvedReports: state.dashboard.approvedReports,
  speciesInfo: state.species,
  marketMetrics: state.marketMetrics
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePeriod,
      incrementStartDate,
      decrementStartDate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StyledDashboard);
