import React from 'react';
import { withStyles } from '@material-ui/styles';
import cn from 'classnames';
import PropTypes from 'prop-types';

const styles = theme => ({
  cardFooter: {
    padding: `${theme.spacing(2)}px 0`
  },
  overline: {
    borderTop: `1px solid ${theme.palette.grey[300]}`
  }
});

const CardFooter = ({ classes, className, children, overline, ...rest }) => {
  const cardFooterClasses = cn({
    [classes.cardFooter]: true,
    [classes.overline]: overline,
    [className]: className !== undefined
  });

  return (
    <div {...rest} className={cardFooterClasses}>
      {children}
    </div>
  );
};

CardFooter.propTypes = {
  overline: PropTypes.bool
};

export default withStyles(styles)(CardFooter);
