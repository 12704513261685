import { GET_SPECIES_CATEGORIES } from "../actions/speciesCategories";

const INITIAL_SPECIES_CATEGORIES_STATE = [];

export default function speciesCategoriesReducer(
  state = INITIAL_SPECIES_CATEGORIES_STATE,
  action = {}
) {
  switch (action.type) {
    case GET_SPECIES_CATEGORIES:
      return action.payload.speciesCategories;
    default:
      return state;
  }
}
