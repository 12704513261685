import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "@sudokrew/wespac-components";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import useFields from "../../hooks/useFields";

function DynamicTooltip(props) {
  const FIELD_NAME = props.field;
  const fields = useFields();

  if (!fields.has(FIELD_NAME)) {
    return null;
  }

  return (
    <Tooltip title={fields.get(FIELD_NAME)}>
      <InfoOutlined style={{ verticalAlign: "text-bottom" }} />
    </Tooltip>
  );
}

DynamicTooltip.propTypes = {
  field: PropTypes.string.isRequired
};

export default DynamicTooltip;
