import React from 'react';
import { Router } from '@reach/router';
import { withStyles } from '@material-ui/core';

import Header from '../views/Header/Header';
import LoginForm from '../views/Login/Login';
import ResetPassword from '../views/ResetPassword/ResetPassword';
import { ProtectedRoute } from '../components';

const styles = theme => ({
  container: {
    paddingTop: '29px'
  }
});

const Login = ({ classes }) => {
  return (
    <div>
      <Header position="fixed" />
      <div className={classes.container}>
        <Router>
          <LoginForm path="/" />
          <ProtectedRoute Component={ResetPassword} path="reset-password" />
        </Router>
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
